<template>
  <div class="t-tag-card" @click="click">
    <div class="t-tag-content" :style="{backgroundColor:color||'#fff'}">
      <div class="t-tag-title">{{title}}</div>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "tag-card",
  props: {
    color:String,
    title:String
  },
  methods:{
    click(){
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.t-tag-card{
  background-color: white;
  border-radius: 20rem ;
  width: calc( 33.33333% - 10rem);
  height: 158rem;
  padding: 24rem 27rem;
  box-shadow: 2rem 2rem 8rem 4rem #F8FAFE;
  .t-tag-content{
    width: 100%;
    height: 100%;
    padding-top: 22rem;
    padding-left: 48rem;
    .t-tag-title{
      font-size: 12rem;
      color: #1D2129;
      margin-bottom: 16rem;
    }
  }
}
</style>
