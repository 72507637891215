<!--德育工作统计-->
<template>
  <div class="moral">
    <div class="linear">
      <div class="search">
        <el-date-picker v-model="search.start_end_date" :picker-options="pickerOptions" end-placeholder="筛选提交结束日期" range-separator="-" size="small" start-placeholder="筛选提交开始日期" style="margin-right: 24rem" type="daterange" value-format="yyyy-MM-dd"></el-date-picker>
        <el-select v-model="search.school_id" class="search_item" placeholder="请选择校区" size="small" @enter="onSearch">
          <el-option v-for="(item,key) in userInfo.school_arr" :key="key" :label="item" :value="key"></el-option>
        </el-select>
        <el-select v-model="search.grade_id" class="search_item" placeholder="请选择年级" size="small" @enter="onSearch">
          <el-option v-for="item in gradesData" :key="item.id" :label="item.name" :value="parseInt(item.id)"></el-option>
        </el-select>
        <el-button class="search_item" size="mini" type="primary" @click="onSearch">查询</el-button>
        <el-button class="search_item" size="mini" type="default" @click="onReset">重置</el-button>
      </div>
      <div class="tags_box">
        <div class="tags" style="margin-bottom: 22rem;cursor: pointer">
          <tag-card v-for="(item,index) in tags" v-if="index < 3" :key="'tags_'+index" :color="item.color" :title="item.title" @click="onJump(index)">
            <div v-if="item.target != null" slot="content" class="content">
              <span :style="{color:item.current === 0 && item.target === 0?'#666666':item.current < item.target?'#F53F3F':'#23C343'}" style="font-size: 28rem;">{{ item.current }}</span>
            </div>
          </tag-card>
        </div>
      </div>
    </div>

    <div style="width: 100%;height: 14rem;background: #f7f7f7"></div>

    <div class="list">
      <l-table
          :config="config1"
          :table-data="table1"
          :total="total[0]"
          class="data-list1"
          style="width: 48%;min-width: 528rem;"
          title="未查早操人员"
          title-bg="#FFF4F1"
          total-color="#F53F3F"
          @downPage="changePage(0,1)"
          @upPage="changePage(0,0)"
      >
        <div slot="title" style="color: #5970FF;cursor: pointer;font-weight: bold" @click="onExportData(0)">导出数据</div>
      </l-table>
      <l-table
          :config="config2"
          :table-data="table2"
          :total="total[1]"
          class="data-list2"
          style="width: 48%;min-width: 528rem;"
          title="德育一对一辅导"
          @downPage="changePage(1,1)"
          @upPage="changePage(1,0)"
      >
        <div slot="title" style="color: #5970FF;cursor: pointer;font-weight: bold" @click="onExportData(1)">导出数据</div>
      </l-table>
    </div>
  </div>
</template>

<script>
import tagCard from "./components/TagCard"
import table from "@/components/TableTo";
import { grades } from "@/api/account-management";
import { mapGetters } from "vuex";

export default {
	_config:{"route":{"path":"moral-education","meta":{"title":"首页"}}},
  computed: {
    ...mapGetters(["userInfo"])
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          // return time.getTime() > Date.now() - 8.64e6; //只能选择今天及今天之前的日期
          return time.getTime() > Date.now() - 8.64e7; //只能选择今天之前的日期，连今天的日期也不能选
        }
      },
      gradesData: [],
      search: {},
      tags: [{
        title: "查早操",
        color: "#FFF8FB",
        current: 0,
        target: 0
      }, {
        title: "德育一对一辅导",
        color: "#FDFDF0",
        current: 0,
        target: 0
      }, {
        title: "班级周报",
        color: "#F4FFF6",
        current: 0,
        target: 0
      }],
      config1: [{
        prop: "statistics_date",
        name: "日期",
      }, {
        prop: "teacher_name",
        name: "班主任",
      }, {
        prop: "class_name",
        name: "行政班"
      }, {
        prop: "school_name",
        name: "校区"
      }],
      config2: [{
        prop: "coach_day",
        name: "日期",
      }, {
        prop: "teacher_name",
        name: "班主任",
      }, {
        prop: "class_name",
        name: "行政班",
      }, {
        prop: "school_name",
        name: "校区"
      }],
      table1: [],
      table2: [],
      // 两个表格的页码
      pages: [1, 1],
      // 数据量
      total: [0, 0]
    }
  },
  components: {
    tagCard,
    "l-table": table
  },
  mounted() {
    grades().then(res => {
      this.gradesData = res.data.data;
    })
    this.getData();
  },
  methods: {

    /**
     * @description 当页面内容被更改
     * @param index  pages的下标
     * @param type 0：上一页 1：下一页
     */
    changePage(index, type) {
      let pageSize = Math.ceil(this.total[index] / 10);
      let page = this.pages[index];
      if (type === 0)
        page--;
      else
        page++;
      if (page <= pageSize && page > 0) {
        this.pages[index] = page;
        if (index === 0) {
          this.getTableData1(this.search);
        } else {
          this.getTableData2(this.search);
        }
      }
    },
    getData() {
      let { search } = this;
      this.$_axios2.get("/api/teacher/moral-statistic/get-statistic", {
        params: { ...search }
      }).then(res => {
        if (res.data.status === 0) {
          let { data } = res.data;
          for (let i of this.tags) {
            if (i.target != null) {
              i.target = data.class_num || 0;
            }
          }
          this.tags[0].current = data.exercise_statistic || 0;
          this.tags[1].current = data.moral_statistic || 0;
          this.tags[2].current = data.week_statistic || 0;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            showClose: true
          })
        }
      })
      this.getTableData1(search);
      this.getTableData2(search);
    },
    // 获取表格1 的数据
    getTableData1(search) {
      this.$_axios2.get("/api/teacher/moral-statistic/get-exercise", {
        params: {
          ...search,
          page: this.pages[0],
          page_size: 10
        }
      }).then(res => {
        let { data } = res;
        if (data.status === 0) {
          this.table1 = data.data.list;
          this.total[0] = data.data.page.total;
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            showClose: true
          })
        }
      }).catch(err => {
      })
    },
    // 获取表格2的数据
    getTableData2(search) {
      this.$_axios2.get("api/teacher/moral-statistic/get-moral", {
        params: {
          ...search,
          page: this.pages[1],
          page_size: 10
        }
      }).then(res => {
        let { data } = res;
        if (data.status === 0) {
          this.table2 = data.data.list;
          this.total[1] = data.data.page.total;
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            showClose: true
          })
        }
      }).catch(err => {
      })
    },
    // 搜索
    onSearch() {
      console.log(this.search)
      this.pages = [1, 1];
      this.getData();
    },
    // 重置
    onReset() {
      this.search = {
        school_id: null,
        grade_id: null,
      }
      this.onSearch();
    },
    // 跳转对应页面
    onJump(val) {
      switch (val) {
        case 0:
          this.$router.push('/moral/teacher-work/morning-exercises/list')
          break;
        case 1:
          this.$router.push('/moral/teacher-work/moral-one-to-one/list')
          break;
        case 2:
          this.$router.push('/moral/teacher-work/class-report/list')
          break
      }
    },
    // 导出数据
    onExportData(val) {
      let { search } = this
      if (!val) {
        this.$_axios2.get("/api/teacher/moral-statistic/get-exercise", {
          params: {
            ...search,
            is_export: 1,
            responseType: 'blob'
          }
        }).then(require => {
          // console.log(require)
          // tools.download('未查早操人员', require.data)
          const filename = '未查早操人员' + '.' + 'csv';
          const blob = new Blob([require.data]);
          let downloadElement = document.createElement('a');
          let href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = decodeURIComponent(filename);
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        })
      } else {
        this.$_axios2.get("api/teacher/moral-statistic/get-moral", {
          params: {
            ...search,
            is_export: 1
          }
        }).then(require => {
          // console.log(require.data)
          // tools.download('德育一对一辅导', require.data)
          const filename = '德育一对一辅导' + '.' + 'csv';
          const blob = new Blob([require.data]);
          let downloadElement = document.createElement('a');
          let href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = decodeURIComponent(filename);
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.layout .right-main .breadNav {
  background-color: #333333;
}

.moral {
  min-width: 1240rem;
  padding: 0;
  //margin-left: 0 !important;
  //width: 100% !important;
  .linear {
    background-image: linear-gradient(180deg, #FBFBFF 0, #fff 100%);
  }

  .search {
    padding: 20rem 25rem 0 25rem;

    .search_item {
      margin-right: 24rem;
      margin-left: 0;
    }
  }

  .tags_box {
    padding: 25rem 50rem;

    .tags {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .content {
        font-size: 26rem;
        display: table-cell;
        width: 100%;
        height: 30rem;
        vertical-align: bottom;
        color: #666;

        span {
          line-height: 1;
        }

        .split {
          font-weight: 200;
          margin: 0 2rem;
        }

      }
    }
  }

  .list {
    display: flex;
    justify-content: space-between;
    margin-top: 42rem;
    padding: 0 50rem;


    .list-card {
      width: 528rem;
      background-color: white;

      .list-header {
        font-size: 20rem;
        padding: 17rem 0 11rem 32rem;
        color: #333333;

        .title {
          font-weight: bold;
        }

        .number {
          font-weight: bold;
          margin-left: 10rem;
          color: #f53f3f;
        }
      }
    }
  }
}
</style>
